import React from 'react'
import Header from '../components/header/Header'


const Home = () => {
    return (
        <>
            <Header />
        </>
    )
}

export default Home